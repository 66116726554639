export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "AI Minecraft",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://oasis.decart.ai/welcome",
    domain: "aiminecraft.games",
    gaId: "G-89MN4GWBG8",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
